<template>
  <main>
    <div class="holder">
      <h1>{{ $t('oauth.wait') }}</h1>
      <p>{{ $t('oauth.process') }}</p>
      <br><br><br><br><br>
  </div>
  </main>
</template>

<script>
    import ApiService from '@/assets/js/services/api.service';
    import { eventBus } from '@/main.js';
    import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'OAuth',
    title: 'Please, wait...',
    data() {
      return {
          url: window.location.origin,
      }
    },
      computed: {
          ...mapGetters(['loginIsValid', 'providers'])
      },
    methods: {
        ...mapActions(['singInWithOAuthCode']),

        goToMain() {
        this.$router.push({ name: 'objects' });
      }
    },
      created() {
          let code = this.$route.query.code;
          let providerId = this.$route.params.providerId;
          console.log('providerId: ' + providerId);
          console.log('code: ' + code);

          let authData = {
              authProviderId: providerId,
              code: code,
              redirectUri: window.location.origin + '/oauth/' + providerId
          };
          let that = this;
          this.singInWithOAuthCode(authData)
              .then(() => {
                  window.location = "/";
              })
              .catch(error => {
                  that.showToast(this.$t('toast.login-error'), 'error')
              })
      },
  };
</script>

<style scoped>

</style>
